<template>
  <tr>
    <th>{{ title }}</th>
    <td v-if="hasFifthColumn"></td>
    <td>
      <input
        class="form-control"
        placeholder=""
        v-model="books"
        @change="emitData"
      />
    </td>
    <td>
      <input
        class="form-control"
        placeholder=""
        v-model="notes"
        @change="emitData"
      />
    </td>
    <td>
      <input
        class="form-control"
        placeholder=""
        v-model="sources"
        @change="emitData"
      />
    </td>
  </tr>
</template>

<script>
export default {
  name: "proformaRow",
  props: ["title", "hasFifthColumn", "id"],
  emits: ["data"],
  data() {
    return {
      books: "",
      notes: "",
      sources: ""
    };
  },
  methods: {
    emitData() {
      var data = {
        index: this.id,
        books: this.books,
        notes: this.notes,
        sources: this.sources,
        title: this.title
      };

      this.$emit("data", data);
    }
  }
};
</script>

<style scoped></style>
