<template>
  <div class="container-proforma myDivClass" id="container-fluid'">
    <Header />

    <div class="container1">
      <h1 class="text-center h3"><u>Proforma for Civil Services</u></h1>

      <div class="form-container container">
        <h2 class="h6">
          Kindly Fill in the details for
          <strong><i>Basic Information </i></strong> regarding preparation and
          CSE history
        </h2>

        <div class="basic-info text-center form-control" id="Printout_21571">
          <form id="part-1'">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  placeholder="Name"
                  v-model="name"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email"
                  v-model="email"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="phone">Phone Number</label>
              <input
                type="number"
                class="form-control"
                id="phone"
                placeholder="9876543210"
                v-model="phoneNumber"
              />
            </div>
            <div class="form-group">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="prelims">Number of Prelims</label>
                  <input
                    type="text"
                    class="form-control"
                    id="prelims"
                    placeholder=""
                    v-model="numberOfPrelims"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="mains">Number of Mains</label>
                  <input
                    type="email"
                    class="form-control"
                    id="mains"
                    placeholder=""
                    v-model="numberOfMains"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="interviews">Number of Interviews</label>
              <input
                type="number"
                class="form-control"
                id="interviews"
                placeholder=""
                v-model="numberOfInterviews"
              />
            </div>
          </form>
        </div>

        <br />

        <h3 class="h6">
          <strong><u>Prelims Specific Information:</u></strong>
        </h3>

        <div class="specific-info text-center form-control" id="part-2">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Subject</th>
                <th scope="col">Sub <br />Categorization</th>
                <th scope="col">Books</th>
                <th scope="col">Notes <br />Coaching</th>
                <th scope="col">
                  Any Other <br />
                  Source(online)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" rowspan="3">History</th>

                <td>Modern India</td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="modernIndiaBooks"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="modernIndiaNotes"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="modernIndiaOther"
                  />
                </td>
              </tr>
              <tr>
                <td>Ancient</td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="ancientAndCultureBooks"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="ancientAndCultureNotes"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="ancientAndCultureSources"
                  />
                </td>
              </tr>
              <tr>
                <td>Medieval</td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="medievalAndCultureBooks"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="medievalAndCultureNotes"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="medievalAndCultureSources"
                  />
                </td>
              </tr>

              <tr>
                <th rowspan="3">Geography</th>
                <td>Physical Geography</td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="physicalGeographyAndCultureBooks"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="physicalGeographyAndCultureNotes"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="physicalGeographyAndCultureSources"
                  />
                </td>
              </tr>
              <tr>
                <td>Indian Geography</td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="indianGeographyAndCultureBooks"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="indianGeographyAndCultureNotes"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="indianGeographyAndCultureSources"
                  />
                </td>
              </tr>
              <tr>
                <td>Human Geography</td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="humanGeographyAndCultureBooks"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="humanGeographyAndCultureNotes"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    placeholder=""
                    v-model="humanGeographyAndCultureSources"
                  />
                </td>
              </tr>
              <proforma-row
                v-for="(row, index) in prelims"
                :title="row"
                :key="index"
                :has-fifth-column="true"
                :id="index"
                @data="saveSubjects"
              ></proforma-row>
            </tbody>
          </table>
        </div>
        <br />
        <h3 class="h6">
          <strong><u>Mains Specific:</u></strong>
        </h3>

        <div class="specific-info text-center form-control">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Subject</th>
                <th scope="col">Books</th>
                <th scope="col">Notes <br />Coaching</th>
                <th scope="col">
                  Any Other <br />
                  Source(online)
                </th>
              </tr>
            </thead>
            <tbody>
              <proforma-row
                :has-fifth-column="false"
                v-for="(main, index) in Mains"
                :key="index"
                :title="main"
                :id="Number('1' + index)"
                @data="saveSubjects"
              ></proforma-row>
            </tbody>
          </table>
        </div>
        <br />
        <h3 class="h6">
          <strong><u>Essay</u></strong>
        </h3>
        <div class="form-control">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="number-of-essay">Number Of Essay Written</label>
              <input
                type="number"
                class="form-control"
                id="number-of-essay"
                v-model="numberOfEssay"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="coaching">Coaching Taken</label>
              <input
                type="number"
                class="form-control"
                id="coaching"
                v-model="coachingTaken"
              />
            </div>
          </div>
        </div>
        <br />

        <h4 class="h6">
          <strong><u>Optional</u></strong>
        </h4>
        <div class="form-control">
          <div class="form-group">
            <label for="optional-sub">Optional Subject:</label>
            <input
              type="text"
              class="form-control"
              id="optional-sub"
              v-model="optionalSubject"
            />
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="optional-sub-if"
                >Coaching For Optional(If Yes Names(s):</label
              >
              <input
                type="text"
                class="form-control"
                id="optional-sub-if"
                v-model="coachingForOptional"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="optional-test-if"
                >Test Series For Optional(If any):</label
              >
              <input
                type="text"
                class="form-control"
                id="optional-test-if"
                v-model="testSeriesOfOptional"
              />
            </div>
          </div>
        </div>

        <br />
        <br />
        <h5 class="h6">
          <strong><u>Your Daily Average Routine:</u></strong>
        </h5>

        <div class="form-control">
          <DailyRoutineFormGroup
            v-for="(daily, index) in dailyRoutine"
            :key="index"
            :title="daily.title"
            :id="daily.id"
            :index="index"
            @dailyRoutine="dailyRoutineDataSave"
          ></DailyRoutineFormGroup>
        </div>
        <br />

        <h5 class="h6">
          <strong><u>Student Feedback:</u></strong>
        </h5>
        <div class="form-control">
          <StudentFeeedbackForm
            v-for="(feedback, index) in feedbacks"
            :key="index"
            :title="feedback.title"
            :id="feedback.id"
            :index="index"
            @feedback="saveFeedback"
          ></StudentFeeedbackForm>
          <button class="btn btn-primary" id="create_pdf" @click="save">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/components/Header";
import ProformaRow from "@/components/proformaRow";
import DailyRoutineFormGroup from "@/components/DailyRoutineFormGroup";
import StudentFeeedbackForm from "@/components/StudentFeeedbackForm";

import axios from "axios";
import router from "@/router";

export default {
  name: "Proforma",
  components: {
    StudentFeeedbackForm,
    DailyRoutineFormGroup,
    ProformaRow,
    Header,
  },
  mounted() {
    store.state.loading = false;
  },
  data() {
    return {
      prelims: [
        "Art & Culture",
        "Indian Politics",
        "Economics",
        "Environment",
        "International Relations",
        "Science & Tech",
        "Disaster",
        "Security",
        "Current Affairs",
        "CSAT",
      ],
      Mains: [
        "World History",
        "Post Independence History",
        "Sociology",
        "Governance",
        "Ethics",
      ],
      dailyRoutine: [
        {
          title: "Average Study Hours:",
          id: "avg-study",
        },
        { title: "Working/Job Duration (if any):", id: "work-hour" },
        { title: "Daily Newspaper(s) and Hours", id: "daily-news" },
        { title: "Preferred Subjects(s) in GS", id: "preferred-sub" },
        { title: "Daily Mains Answer Writing", id: "daily-mains" },
        { title: "Daily Pre Que Solved [Hours]", id: "daily-pre" },
        { title: "Daily Revision/Discussions [Hours]", id: "daily-revision" },
        { title: "Daily Current Affairs[Hours]", id: "daily-current" },
        { title: "Weekly Essay(s)", id: "weekly-essay" },
        { title: "Weekly CSAT(TESTS)", id: "weekly-csat" },
      ],
      feedbacks: [
        {
          title: "Your Weak Subjects",
          id: "weak-sub",
        },
        {
          title: "Your Weak Topics(Subject Wise)",
          id: "week-topic",
        },
        {
          title: "Your weak areas/habits towards preparation",
          id: "bad-habit",
        },
        {
          title: "Any other information relevant to preparation:",
          id: "relevant-info",
        },
      ],
      name: "",
      email: "",
      phoneNumber: "",
      numberOfPrelims: "",
      numberOfMains: "",
      numberOfInterviews: "",
      modernIndiaBooks: "",
      modernIndiaNotes: "",
      modernIndiaOther: "",
      ancientAndCultureBooks: "",
      ancientAndCultureNotes: "",
      ancientAndCultureSources: "",
      medievalAndCultureBooks: "",
      medievalAndCultureNotes: "",
      medievalAndCultureSources: "",
      physicalGeographyAndCultureBooks: "",
      physicalGeographyAndCultureNotes: "",
      physicalGeographyAndCultureSources: "",
      indianGeographyAndCultureBooks: "",
      indianGeographyAndCultureNotes: "",
      indianGeographyAndCultureSources: "",
      humanGeographyAndCultureBooks: "",
      humanGeographyAndCultureNotes: "",
      humanGeographyAndCultureSources: "",
      specificInformation: new Array(15),
      dailyRoutineData: new Array(10),
      studentFeedback: new Array(4),
      numberOfEssay: "",
      coachingTaken: "",
      optionalSubject: "",
      coachingForOptional: "",
      testSeriesOfOptional: "",
      averageStudyHours: "",
      workingJobDuration: "",
      dailyNewsPaperAndHours: "",
      preferredSubject: "",
      dailyMainsAnswerWriting: "",
      dailyPreQueSolved: "",
      dailyRevision: "",
      dailyCurrentAffairs: "",
      weeklyEssay: "",
      weeklyCSAT: "",
      weakSubjects: "",
      weakTopics: "",
      weakAreas: "",
      otherInfo: "",
    };
  },
  methods: {
    dailyRoutineDataSave(data) {
      this.dailyRoutineData[Number(data.index)] = data;
    },
    save() {
      this.createPdf();
    },
    saveFeedback(data) {
      console.log(data);
      this.studentFeedback[Number(data.index)] = data;
    },
    saveSubjects(data) {
      this.specificInformation[Number(data.index)] = data;
    },
    createPdf() {
      var dd = {
        content: [
          { text: "Proforma For Civil Services", style: ["header"] },
          { text: "                                  " },
          {
            text: "Basic Information regarding preparation and CSE history:",
            style: ["center"],
          },
          {
            columns: [
              {
                width: "auto",
                text: "Name:",
              },
              {
                width: "*",
                text: this.name,
              },
              {},
              {
                // fixed width
                width: "auto",
                text: "Email:",
              },
              {
                // % width
                width: "*",
                text: this.email,
              },
            ],
          },
          {
            text: "             ",
          },
          {
            columns: [
              {
                width: "auto",
                text: "Phone Number:",
              },
              {
                width: "*",
                text: this.phoneNumber,
              },
              {},
              {
                // fixed width
                width: "auto",
                text: "Number of prelims:",
              },
              {
                // % width
                width: "*",
                text: this.numberOfPrelims,
              },
            ],
          },
          {
            text: "             ",
          },
          {
            columns: [
              {
                width: "auto",
                text: "Number of interviews:",
              },
              {
                width: "*",
                text: this.numberOfInterviews,
              },
              {},
              {
                // fixed width
                width: "auto",
                text: "Number of Mains:",
              },
              {
                // % width
                width: "*",
                text: this.numberOfMains,
              },
            ],
          },
          {
            text: "Prelims Specific Information:",
            margin: [0, 20],
            style: ["header"],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*"],

              body: [
                [
                  "Subject",
                  "Sub Categorisation ",
                  "Books",
                  "Notes (coaching)",
                  "Any Other Source (online)",
                ],
                [
                  { text: "History", rowSpan: 3, style: ["bold"] },
                  "Modern India ",
                  this.modernIndiaBooks,
                  this.modernIndiaNotes,
                  this.modernIndiaOther,
                ],
                [
                  "empty",
                  "Ancient",
                  this.ancientAndCultureBooks,
                  this.ancientAndCultureNotes,
                  this.ancientAndCultureSources,
                ],
                [
                  "empty1",
                  "Medieval",
                  this.medievalAndCultureBooks,
                  this.medievalAndCultureNotes,
                  this.medievalAndCultureSources,
                ],
                [
                  { text: "Geography", rowSpan: 3, style: ["bold"] },
                  "Physical Geography  ",
                  this.physicalGeographyAndCultureBooks,
                  this.physicalGeographyAndCultureNotes,
                  this.physicalGeographyAndCultureSources,
                ],
                [
                  "empty",
                  "Indian Geography ",
                  this.indianGeographyAndCultureBooks,
                  this.indianGeographyAndCultureNotes,
                  this.indianGeographyAndCultureSources,
                ],
                [
                  "empty1",
                  "Human Geography",
                  this.humanGeographyAndCultureBooks,
                  this.humanGeographyAndCultureNotes,
                  this.humanGeographyAndCultureSources,
                ],
                //insert here
              ],
            },
          },
          {
            text: "Mains Specific: Information:",
            margin: [0, 20],
            style: ["header"],
          },
          {
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*"],

              body: [
                [
                  "Subject",
                  "Books",
                  "Notes (coaching)",
                  "Any Other Source (online)",
                ],
                //insert here
              ],
            },
          },
          { text: "Essay", style: ["header"], margin: [0, 19, 0, 5] },

          {
            columns: [
              {
                width: "auto",
                text: "Number of essay written:",
              },
              {
                width: "*",
                text: this.numberOfEssay,
              },

              {
                // fixed width
                width: "auto",
                text: "Coaching Taken",
              },
              {
                // % width
                width: "*",
                text: this.coachingTaken,
              },
            ],
          },
          { text: "Optional", style: ["header"], margin: [0, 19] },
          {
            text: "Optional Subjects:" + this.optionalSubject,
            margin: [0, 5],
          },
          {
            columns: [
              {
                width: "auto",
                text: "Coaching for Optional (if yes name(s)):",
              },
              {
                width: "*",
                text: this.coachingForOptional,
              },

              {
                // fixed width
                width: "auto",
                text: "test Series for Optional (if any):",
              },
              {
                // % width
                width: "*",
                text: this.testSeriesOfOptional,
              },
            ],
          },
          {
            text: "Your Daily AverageRoutine:",
            style: ["header"],
            margin: [0, 25, 0, 5],
          },
        ],
        styles: {
          header: {
            fontSize: 22,
            bold: true,
            alignment: "center",
            underlined: true,
          },
          center: {
            italics: true,
            lineHeight: 3,
            alignment: "center",
          },
          left: {
            alignment: "left",
          },
          bold: {
            bold: true,
          },
        },
      };

      this.specificInformation.forEach((data, index) => {
        var row = [
          { text: data.title, style: ["bold"] },
          "",
          data.books,
          data.notes,
          data.sources,
        ];
        if (index < 10) {
          dd.content[9].table.body.push(row);
        } else {
          row.splice(1, 1);
          dd.content[11].table.body.push(row);
        }
      });

      this.dailyRoutineData.forEach((data, index) => {
        var title = {
          text: data.title,
          margin: [0, 19, 0, 5],
          style: ["bold"],
        };
        var input = {
          text: data.body,
        };
        dd.content.push(title);
        dd.content.push(input);
      });

      dd.content.push({
        text: "Students’s Feedback:",
        style: ["header"],
        margin: [0, 25, 0, 5],
      });

      this.studentFeedback.forEach((data, index) => {
        console.log(data);
        var title = {
          text: data.title,
          margin: [0, 19, 0, 5],
          style: ["bold"],
        };
        var input = {
          text: data.body,
        };
        dd.content.push(title);
        dd.content.push(input);
      });

      var generatedPDF = pdfMake.createPdf(dd);

      generatedPDF.getBase64((data) => {
        this.sendToBackend(data);
      });
      // generatedPDF.open();
    },
    sendToBackend(data) {
      var config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axios
        .post(
          "https://Pysberduck.pythonanywhere.com/api/pdf_mail",
          {
            pdf: data,
          },
          config
        )
        .then((res) => {
          if (res.data == 200) {
            swal("Sent", "Data Sent", "success").then((value) => {
              router.push({
                name: "Home",
              });
            });
          } else swal("Error", "Some error Occured", "error");
        });
    },
  },
};
</script>

<style scoped>
.container1 {
  background-image: linear-gradient(
    to right,
    red,
    5%,
    rgb(255, 220, 220),
    90%,
    red
  );
}
</style>
