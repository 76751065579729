<template>
  <div class="form-group">
    <label :for="id">{{ title }}</label>
    <input
      type="text"
      :id="id"
      class="form-control"
      v-model="input"
      @change="emitData"
    />
  </div>
</template>

<script>
export default {
  name: "DailyRoutineFormGroup",
  props: ["title", "id", "index"],
  emits: ["dailyRoutine"],
  data() {
    return {
      input: ""
    };
  },
  methods: {
    emitData() {
      var data = {
        index: this.index,
        title: this.title,
        body: this.input
      };

      this.$emit("dailyRoutine", data);
    }
  }
};
</script>

<style scoped></style>
